import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { Link } from 'gatsby-plugin-react-i18next'
import { breakpoints, colors, fonts, fontSizes } from 'src/utils/styles'
import CartIcon from 'src/static/icons/svg/ll_shopping-cart_empty.svg'
import CartIconWhite from 'src/static/icons/svg/dark-mode/ll_shopping-cart_empty_white.svg'

const CartIconStyles = css`
  width: 20px;
  height: auto;
  vertical-align: middle;
`

const MenuLinkStyles = css`
  text-decoration: none;
  font-size: ${fontSizes.verticalNavigationText};
  text-transform: uppercase;
  border-bottom: 0;
  padding: 0 12px;
  font-family: ${fonts.extendedSemiBold};

  :hover {
    text-decoration: underline;
  }

  @media (max-width: ${breakpoints.tablet}px) {
    font-size: ${fontSizes.expandedLink};
  }
`

const ExpandedListStyles = css`
  list-style-type: none;
  padding-inline-start: 15px;
  margin-block-start: 0.5em;
  margin-block-end: 0.5em;
`

export const Wrapper = styled.div`
  background: transparent;
  max-width: 1920px;
  margin: 0 auto;
  margin-bottom: 2.75rem;
  display: flex;
  @media (max-width: ${breakpoints.l}px) {
    display: none;
  }
`

export const WrapperMobile = styled.div`
  background: transparent;
  max-width: 1920px;
  margin: 0 auto;
  display: none;
  overflow: hidden;

  @media (max-width: ${breakpoints.l}px) {
    display: flex;
    margin-bottom: 0.5em;
  }
`

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  padding: 1.45rem 1.6rem 0;
  margin: 1.3rem 4.1rem 0;
  max-width: 100%;
  width: 100%;

  @media (max-width: ${breakpoints.s}px) {
    margin: 0.5rem;
  }
`

export const ContainerMobile = styled.div`
  display: flex;
  justify-content: center;
  align-items: baseline;
  padding: 0.8rem 1.45rem 8px;
  margin: 0.5rem 3rem 0;
  max-width: 100%;
  width: 100%;
  position: relative;

  @media (max-width: ${breakpoints.s}px) {
    padding: 0.8rem 1.45rem 0;
    margin: 0.5rem;
  }
`

export const ShoppingBagIcon = styled(CartIcon)`
  ${CartIconStyles};
`

export const ShoppingBagIconWhite = styled(CartIconWhite)`
  ${CartIconStyles};
`

export const BagQuantity = styled.div`
  position: absolute;
  bottom: -5px;
  right: -5px;
  width: 15px;
  box-sizing: content-box;
  height: 15px;
  text-align: center;
  //color: ${colors.darkGrayNoInverter};
  color: white;
  font-family: ${fonts.bold};
  font-size: ${fontSizes.bagQuantity};
  line-height: 13px;
  background-color: ${colors.lemonadeYellow};
  border-width: 1px;
  border-style: solid;
  border-color: ${colors.lemonadeYellow};
  border-image: initial;
  border-radius: 20px;
`

export const NavIcons = styled.div`
  display: flex;
  margin: 0 1em;

  @media (max-width: ${breakpoints.xxl}px) {
    margin: 0 -1.5em;
  }
`

export const NavItemLeft = styled.div`
  position: relative;
`

export const NavItemRight = styled.div`
  position: relative;
  margin: 3px 2px 0 1.5em;
`

export const MobileCartIconWrapper = styled.div`
  position: absolute;
  right: 12px;

  @media (max-width: ${breakpoints.l}px) {
    top: 9px;
    right: -20px;
  }
  @media (max-width: ${breakpoints.s}px) {
    top: 9px;
    right: 12px;
  }
`

export const ExpandedLink = styled(Link)`
  ${MenuLinkStyles};
  color: ${colors.darkGray};
  text-transform: none;
  font-family: ${fonts.regular};
  font-size: ${fontSizes.expandedLink};
`

export const ExpandedListForHeadwear = styled.ul`
  ${ExpandedListStyles};
  display: ${props => (props.showHeadwearLinks ? 'block' : 'none')};
`

export const ExpandedListForApparel = styled.ul`
  ${ExpandedListStyles};
  display: ${props => (props.showApparelLinks ? 'block' : 'none')};
`

export const ExpandedListForCollections = styled.ul`
  ${ExpandedListStyles};
  display: ${props => (props.showCollectionLinks ? 'block' : 'none')};
`

export const ExpandedListItem = styled.li`
  margin-bottom: 0.25em;
`
export const ExpandedListForSale = styled.ul`
  ${ExpandedListStyles};
  display: ${props => (props.showSaleLinks ? 'block' : 'none')};
`
