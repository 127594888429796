import styled from '@emotion/styled'
import CloseSvg from 'src/static/icons/svg/ll_close.svg'
import { breakpoints, colors, fonts, fontSizes } from 'src/utils/styles'
import { css } from '@emotion/react'
import { IS_BLACK_FRIDAY } from 'src/utils/globals'
import CloseSvgWhite from 'src/static/icons/svg/dark-mode/ll_close_white.svg'

export const StHeadlineWrapper = styled.div`
  display: -webkit-box;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #3331314d;
`

export const StCloseButtonIconStyles = css`
  width: 20px;
  height: 20px;
`

export const StCloseButtonIcon = styled(CloseSvg)`
  ${StCloseButtonIconStyles};
`

export const StCloseButtonIconWhite = styled(CloseSvgWhite)`
  ${StCloseButtonIconStyles};
`

export const StCloseButton = styled.button`
  all: unset;
  cursor: pointer;
  margin-top: 1.4em;

  @media (max-width: ${breakpoints.l}px) {
    margin-top: 5px;
  }
`

export const CartWrapper = styled.div`
  margin-right: 6.5em;
  margin-left: 2.14em;

  @media (max-width: ${breakpoints.xxl}px) {
    margin-right: 5.3em;
  }

  display: flex;
  flex-direction: column;
  height: 100%;

  @media (max-width: ${breakpoints.tablet}px) {
    margin-right: 5.3em;
    margin-left: 1.5em;
  }
  @media (max-width: ${breakpoints.l}px) {
    margin-right: 2.2em;
    margin-left: 1.5em;
  }

  @media (max-width: ${breakpoints.s}px) {
    margin-right: 1.5em;
    margin-left: 1.5em;
  }
`

export const StHeadline = styled.h1`
  font-size: ${fontSizes.headline};
  font-family: ${fonts.extendedBold};
  margin-bottom: 0.85em;
  margin-top: 35px;

  @media (max-width: ${breakpoints.tablet}px) {
    margin-top: 16px;
  }

  @media (max-width: ${breakpoints.l}px) {
    font-size: ${fontSizes.sidebarMobileHeadline};
  }
  @media (max-width: ${breakpoints.s}px) {
    font-size: ${fontSizes.sidebarMobileHeadline};
    margin-top: 16px;
  }
`

export const StSubTotal = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`
export const StShippingcostWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 1.25em;
`
export const StShippingcostHeadline = styled.span`
  font-size: ${fontSizes.subHeadline};
  font-family: ${fonts.regular};
  @media (max-width: ${breakpoints.l}px) {
    font-size: ${fontSizes.textMobile};
  }
`
export const StShippingcostText = styled.span`
  font-size: 1em;
  font-family: ${fonts.regular};

  @media (max-width: ${breakpoints.l}px) {
    font-size: ${fontSizes.footerTextSmall};
  }
`

export const StLineItemWrapper = styled.div`
  padding: 1em 0;
  border-bottom: 1px solid #3331314d;
`
export const StPrice = styled.div`
  font-family: ${fonts.bold};
  font-size: ${fontSizes.subHeadline};
  @media (max-width: ${breakpoints.l}px) {
    font-size: ${fontSizes.textMobile};
  }
`

export const StCheckoutButton = styled.button`
  background-color: ${colors.lemonadeYellow};
  border: 2px solid ${colors.lemonadeYellow};
  //color: ${colors.darkGrayNoInverter};
  color: white;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: ${fontSizes.addToBagButton};
  font-family: ${fonts.regular};
  padding: 0.65rem 2.7rem 0.85rem 2.7rem;
  margin: 0.5em 0;
  cursor: pointer;

  :hover {
    background-color: transparent;
    // color: ${IS_BLACK_FRIDAY ? 'white' : 'inherit'};
    color: ${colors.lemonadeYellow};
    border: 2px solid ${colors.lemonadeYellow};
  }

  :disabled {
    color: white;
    background-color: ${colors.soldOut};
    cursor: not-allowed;
    border: 2px solid ${colors.soldOut};
  }

  @media (max-width: ${breakpoints.s}px) {
    font-size: ${fontSizes.addToBagButtonMobile};
  }
`

export const StProceedShoppingButton = styled(StCheckoutButton)`
  background-color: ${IS_BLACK_FRIDAY ? 'black' : 'white'};
  border: 1px solid ${colors.darkGray};
  color: ${IS_BLACK_FRIDAY ? 'white' : '#333131'};

  :hover {
    border: 1px solid ${colors.darkGray};
    color: ${IS_BLACK_FRIDAY ? 'black' : 'white'};
    background-color: ${colors.darkGray};
  }

  :focus {
    outline: 0;
    background-color: ${colors.darkGray};
    color: white;
  }
`

export const StWrapper = styled.div`
  display: grid;
  grid-template-columns: 0.5fr 1fr 0.35fr 0.25fr;
  height: 100%;

  justify-content: space-between;
  grid-column-gap: 2rem;
  align-items: flex-start;
`
export const StInnerGridWrapper = styled.div`
  display: flex;
  flex-direction: column;
  grid-column: 2 / span 3;

  @media (max-width: ${breakpoints.l}px) {
    height: 100%;
    grid-column: 1 / span 4;
  }
`
export const StButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  grid-column: 2 / span 3;
  margin-bottom: 2em;
  margin-top: auto;
`
export const StSubTotalText = styled.h2`
  font-weight: normal;
  margin-top: 0.5em;
  font-family: ${fonts.regular};
  font-size: ${fontSizes.subHeadline};

  @media (max-width: ${breakpoints.l}px) {
    font-size: ${fontSizes.textMobile};
  }
`

export const StEmptyCart = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  max-height: 617.5px;
  height: 100%;
  border-bottom: 1px solid #3331314d;
  font-family: ${fonts.extendedBold};
  font-size: ${fontSizes.footerText};
`

export const StSmallText = styled.span`
  font-size: ${fontSizes.subHeadlineDefault};

  @media (max-width: ${breakpoints.l}px) {
    font-size: ${fontSizes.footerTextSmall};
  }
`

export const StActionButtonWrapper = styled.div`
  margin-top: auto;
`

export const StyledLoadingSvg = styled.img`
  height: 130px;
  margin-left: 2rem;
`
